import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import TimePlugin from "./plugins/TimePlugin";
import PropertiesPlugin from "./plugins/PropertiesPlugin";
import Analytics from './plugins/Analytics';
import { VueReCaptcha } from 'vue-recaptcha-v3';


axios.defaults.baseURL = 'https://tymetro.kizax.cc'
// axios.defaults.baseURL = 'https://127.0.0.1:8000'

createApp(App).use(store).use(router).use(TimePlugin).use(Analytics, {
    measurementId: 'G-94EFTC0HM2' // Your GA4 Measurement ID
}).use(PropertiesPlugin).use(VueReCaptcha, {
    siteKey: '6LcPwZoqAAAAAOiWycVbelJZRlktpqQnS986SpYi',
}).mount('#app')


