export default {
install(app, options) {
        // Load Google Analytics Script
        const loadGoogleAnalytics = () => {
        const script = document.createElement('script')
        script.async = true
        script.src = `https://www.googletagmanager.com/gtag/js?id=${options.measurementId}`
        document.head.appendChild(script)

        window.dataLayer = window.dataLayer || []
        function gtag() {
            window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', options.measurementId)

        // Make gtag available globally
        window.gtag = gtag
    }

    // Track page views
    const trackPage = (to) => {
        if (window.gtag) {
            window.gtag('event', 'page_view', {
            page_path: to.path,
            page_title: document.title,
            page_location: window.location.href
            })
        }
    }

    // Track custom events
    const trackEvent = (eventName, eventParams = {}) => {
        if (window.gtag) {
            window.gtag('event', eventName, eventParams)
        }
    }

    // Initialize GA4
    if (options.measurementId) {
        loadGoogleAnalytics()

        // Add tracking methods to the Vue instance
        app.config.globalProperties.$analytics = {
            trackEvent,
            trackPage
        }

        // Provide analytics through composition API
        app.provide('analytics', {
            trackEvent,
            trackPage
        })
    } else {
        console.warn('GA4 Measurement ID is required')
    }
}
}