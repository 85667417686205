<template>
    <nav>
        <div class="menu-container">
            <router-link to="/" class="logo">桃園機場捷運時刻查詢</router-link>
            <button class="hamburger" :class="{ 'active': is_menu_open }" @click="toggleMenu">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul class="menu" :class="{ 'active': is_menu_open }">
                <router-link to="/" custom v-slot="{ navigate }">
                    <li @click="navigateAndCloseMenu(navigate)" role="link">Home</li>
                </router-link>
                <router-link to="/private_policy" custom v-slot="{ navigate }">
                    <li @click="navigateAndCloseMenu(navigate)" role="link">隱私權政策</li>
                </router-link>
                <router-link to="/problem_report" custom v-slot="{ navigate }">
                    <li @click="navigateAndCloseMenu(navigate)" role="link">問題回報</li>
                </router-link>
            </ul>
        </div>
    </nav>
    <router-view />
</template>

<script>

// import axios from 'axios'

export default {
    name: 'App',
    components: {
    },
    data() {
        return {
        is_menu_open: false,
        }
    },
    mounted() {
        document.title = '桃園機場捷運時刻查詢'
    },
    beforeUpdate() {
    },
    methods: {
        toggleMenu() {
            this.is_menu_open = !this.is_menu_open;
        },
        navigateAndCloseMenu(navigate) {
            navigate();
            this.is_menu_open = false;
        }
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

nav {
    background: #6c5ce7;
    padding: 15px;
}

.menu-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
}

.menu {
    display: flex;
    gap: 20px;
    list-style: none;
}

.menu li {
    color: white;
    cursor: pointer;
}

.hamburger {
    display: none;
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px;
}

.hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background: white;
    margin: 5px 0;
    transition: 0.3s;
}

/* custom input style */

input[type=text],
input[type=tel],
input[type=password],
input[type=number],
input[type=email] {
    width: 100%;
    padding: 1rem;
    margin-bottom: 2.5rem;
    box-sizing: border-box;
    font-size: 1rem;
}

input:focus {
    outline: none;
}

input[type=submit] {
    width: 100%;
    font-size: 1rem;
    background-color: #58A469;
    border: none;
    color: white;
    padding: 1rem 2rem;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 2rem;
}

input[type=submit]:disabled {
    background-color: #bdc3c7;
}

input[type=submit][v-cloak] {
    display: inline-block;
    background-color: #bdc3c7;
}

input[type=file] {
    margin-bottom: 2.5rem;
}

input[type=file]::file-selector-button {
    color: #fff;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 2px;
    background-color: #58A469;
}

input[type=file]::file-selector-button:hover {
    background-color: #248F77;
}

label {
    display: block;
    color: #535c68;
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #fff;
    background-image: none;
    font-size: 1rem;
}

/* Remove IE arrow */
select::-ms-expand {
    display: none;
}

/* Custom Select */
.select {
    position: relative;
    display: flex;
    width: 100%;
    height: 2rem;
    line-height: 2;
    background: #fff;
    overflow: hidden;
    border: 1px solid #767676;
    border-radius: 2px;
    margin-bottom: 1rem;
    font-size: 1rem;
}

select {
    flex: 1;
    padding: 0.2rem 1rem;
    color: #000;
    cursor: pointer;
}

/* Arrow */
.select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0.6rem;
    color: #fff;
    background: #6c5ce7;
    cursor: pointer;
    pointer-events: none;
}

.radio_block {
    margin-bottom: 2.5rem;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]+label {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-bottom: 0;
}

input[type="radio"]+label>span.checked {
    display: none;
}

input[type="radio"]+label>span.unchecked {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    user-select: none;
}

input[type="radio"]:checked+label>span.checked {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    user-select: none;
}

input[type="radio"]:checked+label>span.unchecked {
    display: none;
}

.checkbox_block {
    margin-bottom: 2.5rem;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]+label {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-bottom: 0;
}

input[type="checkbox"]:not(:checked)+label>span.checked {
    display: none;
}

input[type="checkbox"]:not(:checked)+label>span.unchecked {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    user-select: none;
}

input[type="checkbox"]:checked+label>span.checked {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    user-select: none;
}

input[type="checkbox"]:checked+label>span.unchecked {
    display: none;
}

.date_picker {
    margin-bottom: 1.5rem;
}

input.dp__input {
    border-color: #767676;
}

.grow_wrap {
    margin-bottom: 1rem;
}

textarea {
    width: 97%;
    padding: 0.5rem;
    font: inherit;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .menu {
        display: none;
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        background: #a29bfe;
        flex-direction: column;
        padding: 20px;
        text-align: center;
    }

    .menu.active {
        display: flex;
        z-index: 999;
    }

    /* X animation */
    .hamburger.active span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger.active span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active span:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -6px);
    }
}

@media only screen and (min-width: 768px) {
    label {
        margin-bottom: 1rem;
    }
}
</style>
