export default {
    install: (app) => {
        app.config.globalProperties.$api_host = 'https://tymetro.kizax.cc'
        // app.config.globalProperties.$api_host = 'https://127.0.0.1:8000'
        app.config.globalProperties.$cloudfront_host = 'https://d1zyhmlibzhv4g.cloudfront.net'
        app.config.globalProperties.$station_list = [
            {
                "id": "A1",
                "name": "臺北車站"
            },
            {
                "id": "A2",
                "name": "三重站"
            },
            {
                "id": "A3",
                "name": "新北產業園區站"
            },
            {
                "id": "A4",
                "name": "新莊副都心站"
            },
            {
                "id": "A5",
                "name": "泰山站"
            },
            {
                "id": "A6",
                "name": "泰山貴和站"
            },
            {
                "id": "A7",
                "name": "體育大學站"
            },
            {
                "id": "A8",
                "name": "長庚醫院站"
            },
            {
                "id": "A9",
                "name": "林口站"
            },
            {
                "id": "A10",
                "name": "山鼻站"
            },
            {
                "id": "A11",
                "name": "坑口站"
            },
            {
                "id": "A12",
                "name": "機場第一航廈站"
            },
            {
                "id": "A13",
                "name": "機場第二航廈站"
            },
            {
                "id": "A14a",
                "name": "機場旅館站"
            },
            {
                "id": "A15",
                "name": "大園站"
            },
            {
                "id": "A16",
                "name": "橫山站"
            },
            {
                "id": "A17",
                "name": "領航站"
            },
            {
                "id": "A18",
                "name": "高鐵桃園站"
            },
            {
                "id": "A19",
                "name": "桃園體育園區站"
            },
            {
                "id": "A20",
                "name": "興南站"
            },
            {
                "id": "A21",
                "name": "環北站"
            },
            {
                "id": "A22",
                "name": "老街溪站"
            }
        ]



    }
}