import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentInstance } from 'vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
    },
    {
        path: '/problem_report',
        name: 'problem_report',
        component: () => import('../views/ProblemReportView.vue'),
    },
    {
        path: '/private_policy',
        name: 'private_policy',
        component: () => import('../views/PrivatePolicyView.vue'),
    },
    {
        path: '/search_result/:start_station/:end_station/:departure_time',
        name: 'search_result',
        component: () => import('../views/SearchResultView.vue'),
    },
    // {
    //     path: '/404/',
    //     name: '404',
    //     component: () => import('../views/Page404View'),
    //     hidden: true
    // },
    // {
    //     path: '/:catchAll(.*)',
    //     redirect: '/404/',
    //     hidden: true
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.afterEach((to) => {
    const app = getCurrentInstance()
    if (app) {
        app.config.globalProperties.$analytics.trackPage(to)
    }
})

export default router
